import { Accordion, Button, Image, List } from "semantic-ui-react";
import { useState } from "react";
import { ALL_CAKES_NAVBAR_IMAGE } from "components/utils/constants/urlConstants";
import PropTypes from "prop-types";
import {
  ALL_CAKES,
  CONTACT_US,
} from "components/utils/constants/routeConstants";
import { useDispatch } from "react-redux";
import { toggle } from "features/sidebarSlice";
import { useNavigate } from "react-router-dom";
import { SidebarHeader } from "./SidebarHeader";
import "./MobileSidebar.css";
import "./GiftsSidebar.css";
import "../DesktopNavbar/DesktopNavbar.css";

const CakesSidebar = ({ toggleMiniSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const onCustomiseClick = () => {
    toggleMiniSidebar("");
    dispatch(toggle(false));
    navigate(CONTACT_US);
  };

  return (
    <div className="absolute bg-primary-cream sidebar px-9 py-4 overflow-hidden sidebar-animate-right">
      <SidebarHeader toggleMiniSidebar={toggleMiniSidebar} />
      <div className="mobile-inter-15 font-semibold text-center mb-5 ">
        CAKES
      </div>

      <div>
        <Accordion>
          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 6}
            index={6}
            onClick={handleClick}
          >
            <a className="hover:text-black" href={ALL_CAKES}>
              All Cakes
            </a>
          </Accordion.Title>

          {/* <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
          >
            <a
              className="hover:text-black"
              href={`${ALL_CAKES}?id=2&value=${process.env.REACT_APP_SEASONAL_CAKE}`}
            >
              Seasonal Cakes
            </a>
          </Accordion.Title> */}

          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
          >
            <a
              className="hover:text-black"
              href={`${ALL_CAKES}?id=3&value=${process.env.REACT_APP_CUSTOMISED_CAKE}`}
            >
              {" "}
              Customised Cakes
            </a>
          </Accordion.Title>

          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 1}
            index={1}
            onClick={handleClick}
          >
            <a
              className="hover:text-black"
              href={`${ALL_CAKES}?id=4&value=${process.env.REACT_APP_CLASSIC_CAKE}`}
            >
              Classic Cakes
            </a>
          </Accordion.Title>

          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 1}
            index={1}
            onClick={handleClick}
          >
            <a
              className="hover:text-black"
              href={`${ALL_CAKES}?id=1&value=${process.env.REACT_APP_BENTO_CAKE}`}
            >
              Bento Cakes
            </a>
          </Accordion.Title>

          {/* <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 2}
            index={2}
            onClick={handleClick}
          >
            <div>CELEBRATION CAKES</div>
            <Icon className="text-[1.5rem]" name="dropdown text-end" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <ul>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Cakes for Men & Women </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/"> Cakes for Boys </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/"> Cakes for Girls </a>
              </li>
            </ul>
          </Accordion.Content> */}

          {/* <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2"
            active={activeIndex === 3}
            index={3}
            onClick={handleClick}
          >
            <div>SEASONAL CAKES</div>
            <Icon name="dropdown text-end" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <ul>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Halloween </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Diwali </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Christmas </a>
              </li>
              <li className="mb-4 mobile-inter-15 font-normal">
                <a href="/">Chinese New Year </a>
              </li>
            </ul>
          </Accordion.Content> */}
        </Accordion>

        <div className="mt-10">
          <Button
            type="submit"
            onClick={onCustomiseClick}
            className="customised-btn mobile-inter-15 font-semibold  "
          >
            Customise
            <Image
              loading="lazy"
              className="giftbox"
              alt="giftbox Logo"
              src="/assets/images/icons/PINCH_icon_gifting.png"
              size="small"
            />
          </Button>
        </div>
        <hr className="border-solid border-t-1 border-[#6d6d6d] mb-8 mt-10" />
        <div className="mb-14 mt-10">
          <a href={ALL_CAKES}>
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={ALL_CAKES_NAVBAR_IMAGE}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link"
            href={ALL_CAKES}
            active
          >
            Shop All
          </List.Item>
        </div>
      </div>
    </div>
  );
};

export default CakesSidebar;

CakesSidebar.propTypes = {
  toggleMiniSidebar: PropTypes.func.isRequired,
};
