import { Accordion, Button, Image, List } from "semantic-ui-react";
import { useState } from "react";
import {
  ALL_BAKES_NAVBAR_IMAGE,
  HERO_BANNER_TWO,
} from "components/utils/constants/urlConstants";
import PropTypes from "prop-types";
import {
  ALL_BAKES,
  CONTACT_US,
} from "components/utils/constants/routeConstants";
import { toggle } from "features/sidebarSlice";
import "./MobileSidebar.css";
import "./GiftsSidebar.css";
import "../DesktopNavbar/DesktopNavbar.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SidebarHeader } from "./SidebarHeader";

const BakesSidebar = ({ toggleMiniSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const onCustomiseClick = () => {
    toggleMiniSidebar("");
    dispatch(toggle(false));
    navigate(CONTACT_US);
  };

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <div className="absolute bg-primary-cream sidebar px-9 py-4 overflow-hidden sidebar-animate-right">
      <SidebarHeader toggleMiniSidebar={toggleMiniSidebar} />
      <div className="mobile-inter-15 font-semibold text-center mb-5 ">
        BAKES
      </div>

      <div>
        <Accordion>
          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
          >
            <div>
              <a className="hover:text-black" href={ALL_BAKES}>
                All Bakes
              </a>
            </div>
          </Accordion.Title>

          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 1}
            index={1}
            onClick={handleClick}
          >
            <div>
              <a
                className="hover:text-black"
                href={`${ALL_BAKES}?id=${process.env.REACT_APP_CUPCAKES}`}
              >
                Cupcakes
              </a>
            </div>
            {/* <Icon className="text-[1.5rem]" name="dropdown text-end" /> */}
          </Accordion.Title>

          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 2}
            index={2}
            onClick={handleClick}
          >
            <div>
              <a
                className="hover:text-black"
                href={`${ALL_BAKES}?id=${process.env.REACT_APP_MINI_BITES}`}
              >
                Mini Bites
              </a>
            </div>
          </Accordion.Title>

          <Accordion.Title
            className="mobile-inter-15 font-semibold flex justify-between mt-2 "
            active={activeIndex === 3}
            index={3}
            onClick={handleClick}
          >
            <div>
              <a
                className="hover:text-black"
                href={`${ALL_BAKES}?id=${process.env.REACT_APP_SEASONAL_BAKES}`}
              >
                Seasonal Bakes
              </a>
            </div>
          </Accordion.Title>
        </Accordion>

        <div className="mt-10">
          <Button
            type="submit"
            onClick={onCustomiseClick}
            className="customised-btn mobile-inter-15 font-semibold  "
          >
            Customise
            <Image
              loading="lazy"
              className="giftbox"
              alt="giftbox Logo"
              src="/assets/images/icons/PINCH_icon_gifting.png"
              size="small"
            />
          </Button>
        </div>
        <hr className="border-solid border-t-1 border-[#6d6d6d] mb-8 mt-10" />
        <div className="mb-14 mt-10">
          <a href={`${ALL_BAKES}?id=${process.env.REACT_APP_SUGAR_FREE}`}>
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={HERO_BANNER_TWO}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link "
            href={`${ALL_BAKES}?id=${process.env.REACT_APP_SUGAR_FREE}`}
            active
          >
            Sugar-free Delights
          </List.Item>
        </div>

        <div className="mb-14 mt-10">
          <a href={ALL_BAKES}>
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={ALL_BAKES_NAVBAR_IMAGE}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link "
            href={ALL_BAKES}
            active
          >
            Shop All
          </List.Item>
        </div>
      </div>
    </div>
  );
};

export default BakesSidebar;

BakesSidebar.propTypes = {
  toggleMiniSidebar: PropTypes.func.isRequired,
};
