import { Image, List } from "semantic-ui-react";
import {
  NEW_YEAR_CAKES,
  NEW_YEAR_COOKIES,
} from "components/utils/constants/urlConstants";
import PropTypes from "prop-types";
import {
  ALL_BAKES,
  ALL_CAKES,
} from "components/utils/constants/routeConstants";
import { SidebarHeader } from "./SidebarHeader";
import "./MobileSidebar.css";
import "./GiftsSidebar.css";
import "../DesktopNavbar/DesktopNavbar.css";

const OccasionSidebar = ({ toggleMiniSidebar }) => {
  return (
    <div className="absolute bg-primary-cream sidebar px-9 py-4 overflow-hidden sidebar-animate-right">
      <SidebarHeader toggleMiniSidebar={toggleMiniSidebar} />
      <div className="mobile-inter-15 font-semibold text-center mb-5 ">
        CHINESE NEW YEAR
      </div>

      <div>
        <div className="mb-14 mt-10">
          <a
            href={`${ALL_CAKES}?id=2&value=${process.env.REACT_APP_SEASONAL_CAKE}`}
          >
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={NEW_YEAR_CAKES}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link"
            href={`${ALL_CAKES}?id=2&value=${process.env.REACT_APP_SEASONAL_CAKE}`}
            active
          >
            Chinese New Year Cakes
          </List.Item>
        </div>
        <div className="mb-14 mt-10">
          <a href={`${ALL_BAKES}?id=${process.env.REACT_APP_SEASONAL_BAKES}`}>
            <Image
              loading="lazy"
              className="mb-1 mobile-navbar-images"
              alt="Packaging image"
              size="large"
              src={NEW_YEAR_COOKIES}
            />
          </a>

          <List.Item
            className="mobile-headline-20  navbar-image-text-link"
            href={`${ALL_BAKES}?id=${process.env.REACT_APP_SEASONAL_BAKES}`}
            active
          >
            Chinese New Year Snacks
          </List.Item>
        </div>
      </div>
    </div>
  );
};

export default OccasionSidebar;

OccasionSidebar.propTypes = {
  toggleMiniSidebar: PropTypes.func.isRequired,
};
